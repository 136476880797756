import Radium from 'radium';
import React from "react";

function Team() {
    return (
        <>
            <div className="breadcrumb-area bg-gray">
                <div className="container">
                    <div className="breadcrumb-item">
                        <div className="breadcrum-shape">
                            <img src="/assets/img/shape/bg-shape-3.png" alt="shape" />
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li><a href="/"><i className="fas fa-home"></i> Home</a></li>
                                        <li className="active">Team members</li>
                                    </ol>
                                </nav>
                                <h1>Professional team members</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="team-style-one-area bg-cover default-padding bottom-less">

                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-6 mb-30">
                            <div className="team-style-one">
                                <div className="thumb">
                                    <img src="/assets/img/800x800.png" alt="1" />
                                        <ul className="social">
                                            <li className="facebook">
                                                <a href="/">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li className="twitter">
                                                <a href="/">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        </ul>
                                </div>
                                <div className="content">
                                    <a href="/"><i className="fas fa-comment-alt-lines"></i></a>
                                    <div className="info">
                                        <h4><a href="/">James Baker</a></h4>
                                        <span>Marketing Expert</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 mb-30">
                            <div className="team-style-one">
                                <div className="thumb">
                                    <img src="/assets/img/800x800.png" alt="2" />
                                        <ul className="social">
                                            <li className="facebook">
                                                <a href="/">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li className="twitter">
                                                <a href="/">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        </ul>
                                </div>
                                <div className="content">
                                    <a href="/"><i className="fas fa-comment-alt-lines"></i></a>
                                    <div className="info">
                                        <h4><a href="/">Dalton Grant</a></h4>
                                        <span>Project Manager</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 mb-30">
                            <div className="team-style-one">
                                <div className="thumb">
                                    <img src="/assets/img/800x800.png" alt="3" />
                                        <ul className="social">
                                            <li className="facebook">
                                                <a href="/">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li className="twitter">
                                                <a href="/">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        </ul>
                                </div>
                                <div className="content">
                                    <a href="/"><i className="fas fa-comment-alt-lines"></i></a>
                                    <div className="info">
                                        <h4><a href="/">Ryan Ricketts</a></h4>
                                        <span>Consulting Officer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 mb-30">
                            <div className="team-style-one">
                                <div className="thumb">
                                    <img src="/assets/img/800x800.png" alt="4" />
                                        <ul className="social">
                                            <li className="facebook">
                                                <a href="/">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li className="twitter">
                                                <a href="/">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        </ul>
                                </div>
                                <div className="content">
                                    <a href="/"><i className="fas fa-comment-alt-lines"></i></a>
                                    <div className="info">
                                        <h4><a href="/">Danny Russell</a></h4>
                                        <span>Creative Director</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 mb-30">
                            <div className="team-style-one">
                                <div className="thumb">
                                    <img src="/assets/img/800x800.png" alt="5" />
                                        <ul className="social">
                                            <li className="facebook">
                                                <a href="/">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li className="twitter">
                                                <a href="/">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        </ul>
                                </div>
                                <div className="content">
                                    <a href="/"><i className="fas fa-comment-alt-lines"></i></a>
                                    <div className="info">
                                        <h4><a href="/">James Baker</a></h4>
                                        <span>Marketing Expert</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 mb-30">
                            <div className="team-style-one">
                                <div className="thumb">
                                    <img src="/assets/img/800x800.png" alt="6" />
                                        <ul className="social">
                                            <li className="facebook">
                                                <a href="/">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li className="twitter">
                                                <a href="/">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </li>
                                        </ul>
                                </div>
                                <div className="content">
                                    <a href="/"><i className="fas fa-comment-alt-lines"></i></a>
                                    <div className="info">
                                        <h4><a href="/">Dalton Grant</a></h4>
                                        <span>Project Manager</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Radium(Team);
