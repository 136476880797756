import routes, {getRoutes} from "./routes";
import {Route, Routes, Navigate} from "react-router-dom";
import Radium, { StyleRoot } from 'radium';

function App() {
  return (
    <StyleRoot>
        <Routes>
          {getRoutes(routes())}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </StyleRoot>
  );
}

export default Radium(App);
