import Radium from 'radium';
import React from "react";

function Projects() {
    return (
        <>
            <div className="breadcrumb-area bg-gray">
                <div className="container">
                    <div className="breadcrumb-item">
                        <div className="breadcrum-shape">
                            <img src="/assets/img/shape/bg-shape-3.png" alt="shape" />
                        </div>
                        <div className="row">
                            <div className="col-lg-8">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li><a href="/"><i className="fas fa-home"></i> Home</a></li>
                                        <li className="active">Case Studies</li>
                                    </ol>
                                </nav>
                                <h1>Take a look some of <br /> our recent case studies</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="portfolio-style-one-area default-padding">
                <div className="container">
                    <div className="row mt--50">
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInUp">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/800x800.png" alt="1" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Marketing</span>
                                        <h2><a href="/">Photo shooting and editing</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInDown" data-duration="1.5s">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/800x600.png" alt="2" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Creative</span>
                                        <h2><a href="/">Quality in industrial design</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInUp" data-duration="2s">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/800x800.png" alt="3" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Design</span>
                                        <h2><a href="/">Blue business mockup cards</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInDown" data-duration="2.5s">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/800x600.png" alt="4" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Business</span>
                                        <h2><a href="/">Simple black & white design	</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Radium(Projects);
