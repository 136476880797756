import Radium from 'radium';
import React from "react";

function Index() {
    return (
        <>
            <div className="banner-style-one" style={{backgroundImage: "url(assets/img/shape/1.png)"}}>
                <div className="container">
                    <div className="row align-center">
                        <div className="col-lg-8 banner-one-item">
                            <h4>Creative digital studio</h4>
                            <h2>Build great <strong>portfolio</strong></h2>
                        </div>
                        <div className="col-lg-3 offset-lg-1 banner-one-item text-center">
                            <div className="curve-text">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150" version="1.1">
                                    <path id="textPath" d="M 0,75 a 75,75 0 1,1 0,1 z"></path>
                                    <text><textPath href="#textPath">Award Winning Agency</textPath></text>
                                </svg>
                                <a href="https://www.youtube.com/watch?v=ipUuoMCEbDQ" className="popup-youtube"><i className="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="banner-animation-zoom">
                <div className="animation-zoom-banner" style={{backgroundImage: "url(assets/img/banner/1.jpg)"}} id="js-hero"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-6">
                            <div className="experience-box">
                                <div className="inner-content">
                                    <h2><strong>28+</strong> Years of experience</h2>
                                    <p>
                                        These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled data structure.
                                    </p>
                                    <a className="btn-animation" href="/"><i className="fas fa-arrow-right"></i> <span>Our Services</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="creative-services-area overflow-hidden default-padding">

                <div className="bg-static">
                    <img className="bg-move" src="/assets/img/shape/5.png" alt="1" />
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="site-heading">
                                <h4 className="sub-title">Services We Offer</h4>
                                <h2 className="title">Turn Information <br /> Into Actionable Insights</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container container-stage">
                    <div className="row">
                        <div className="col-lg-12">

                            <div className="services-item-one-items">
                                <div className="services-nav">
                                    <div className="nav-items">
                                        <div className="services-button-prev"></div>
                                        <div className="services-button-next"></div>
                                    </div>
                                </div>
                                <div className="services-carousel swiper">
                                    <div className="swiper-wrapper">

                                        <div className="swiper-slide">
                                            <div className="cteative-service-item">
                                                <img src="/assets/img/icon/1.png" alt="Icon" />
                                                <h4><a href="/">Digital Marketing</a></h4>
                                                <p>
                                                    Continued at up zealously necessary is regular breakfast. Surrounded motionless she end literature. Time is neglects supported yet melancho themselve. Biggest agencies. The user experience: the first mention of the user and its.
                                                </p>
                                                <span>01</span>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="cteative-service-item">
                                                <img src="/assets/img/icon/2.png" alt="Icon" />
                                                <h4><a href="/">Growth Tracking</a></h4>
                                                <p>
                                                    Continued at up zealously necessary is regular breakfast. Surrounded motionless she end literature. Time is neglects supported yet melancho themselve. Biggest agencies. The user experience: the first mention of the user and its.
                                                </p>
                                                <span>02</span>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="cteative-service-item">
                                                <img src="/assets/img/icon/3.png" alt="Icon" />
                                                <h4><a href="/">Product Branding</a></h4>
                                                <p>
                                                    Continued at up zealously necessary is regular breakfast. Surrounded motionless she end literature. Time is neglects supported yet melancho themselve. Biggest agencies. The user experience: the first mention of the user and its.
                                                </p>
                                                <span>03</span>
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="cteative-service-item">
                                                <img src="/assets/img/icon/4.png" alt="Icon" />
                                                <h4><a href="/">Enterprise Consulting</a></h4>
                                                <p>
                                                    Continued at up zealously necessary is regular breakfast. Surrounded motionless she end literature. Time is neglects supported yet melancho themselve. Biggest agencies. The user experience: the first mention of the user and its.
                                                </p>
                                                <span>04</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area default-padding-bottom relative">
                <div className="blur-bg-theme"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-6">
                            <div className="about-style-one-thumb animate" data-animate="fadeInUp">
                                <img src="/assets/img/about/1.jpg" alt="2" />
                                <div className="fun-fact text-light animate" data-animate="fadeInDown" data-duration="1s">
                                    <div className="counter">
                                        <div className="timer" data-to="38" data-speed="2000">38</div>
                                        <div className="operator">K</div>
                                    </div>
                                    <span className="medium">Completed Projects</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 pl-80 pl-md-15 pl-xs-15">
                            <div className="about-style-one">
                                <h2 className="title mb-30">Creative process in our agency</h2>
                                <p>
                                    From traditional and thought leadership campaigns
                                    to compress. storytelling and creative social media management
                                    we’ve got you covered. Something is not your average
                                    order-taking vendor. We are proud to be the go-to
                                    partner for some of the world’s biggest international agencies
                                    andbrands. Seeing rather her.
                                </p>
                                <ul className="list-simple">
                                    <li>Amazing communication.</li>
                                    <li>Best trendinf designing experience.</li>
                                </ul>
                                <a href="/" className="arrow-btn"><i className="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="portfolio-style-one-area default-padding bg-gray">

                <div className="container">
                    <div className="heading-left">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content-left">
                                    <h4 className="sub-title">Popular Projects</h4>
                                    <h2 className="title">Featured Works</h2>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <p>
                                    Continue indulged speaking the was out horrible for domestic position. Seeing rather her you not esteem men settle genius excuse. Deal say over you age from. Comparison new ham melancholy themselves.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mt--50 mt-md-3-0 mt-xs--30">
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInUp">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/projects/1.jpg" alt="3" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Marketing</span>
                                        <h2><a href="/">Photo shooting and editing</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInDown" data-duration="1.5s">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/projects/2.jpg" alt="4" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Creative</span>
                                        <h2><a href="/">Quality in industrial design</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInUp" data-duration="2s">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/projects/3.jpg" alt="5" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Design</span>
                                        <h2><a href="/">Blue business mockup cards</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 item-center">
                            <div className="portfolio-style-one animate" data-animate="fadeInDown" data-duration="2.5s">
                                <div className="thumb-zoom">
                                    <img src="/assets/img/projects/4.jpg" alt="6" />
                                </div>
                                <div className="pf-item-info">
                                    <div className="content-info">
                                        <span>Business</span>
                                        <h2><a href="/">Simple black & white design	</a></h2>
                                    </div>
                                    <div className="button">
                                        <a href="/" className="pf-btn"><i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fun-factor-circle-area default-padding-bottom bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="fun-fact-circle-lists">
                                <div className="fun-fact animate" data-animate="fadeInDown">
                                    <div className="counter">
                                        <div className="timer" data-to="360" data-speed="3000">360</div>
                                        <div className="operator">K</div>
                                    </div>
                                    <span className="medium">World Customer</span>
                                </div>
                                <div className="fun-fact animate" data-animate="fadeInUp" data-duration="0.5s">
                                    <div className="counter">
                                        <div className="timer" data-to="98" data-speed="3000">98</div>
                                        <div className="operator">%</div>
                                    </div>
                                    <span className="medium">Positive Rating</span>
                                </div>
                                <div className="fun-fact animate" data-animate="fadeInDown">
                                    <div className="counter">
                                        <div className="timer" data-to="874" data-speed="3000">874</div>
                                        <div className="operator">+</div>
                                    </div>
                                    <span className="medium">Total Branch</span>
                                </div>
                                <div className="fun-fact animate" data-animate="fadeInUp" data-duration="0.5s">
                                    <div className="counter">
                                        <div className="timer" data-to="35" data-speed="1000">35</div>
                                    </div>
                                    <span className="medium">Years experience</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="brand-area relative overflow-hidden bg-gradient text-light">
                <div className="brand-style-one">
                    <div className="container-fill">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="brand-items">
                                    <div className="brand-conetnt">
                                        <div className="item">
                                            <h2>Marketing</h2>
                                        </div>
                                        <div className="item">
                                            <h2>Branding</h2>
                                        </div>
                                        <div className="item">
                                            <h2>Design</h2>
                                        </div>
                                        <div className="item">
                                            <h2>Development</h2>
                                        </div>
                                    </div>
                                    <div className="brand-conetnt">
                                        <div className="item">
                                            <h2>Marketing</h2>
                                        </div>
                                        <div className="item">
                                            <h2>Branding</h2>
                                        </div>
                                        <div className="item">
                                            <h2>Design</h2>
                                        </div>
                                        <div className="item">
                                            <h2>Development</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="choose-us-style-one-area default-padding bg-gray">
                <div className="container">
                    <div className="row align-center">
                        <div className="col-lg-6">
                            <div className="choose-us-style-one-thumb">
                                <img src="/assets/img/about/2.jpg" alt="7" />
                                <a  href="https://www.youtube.com/watch?v=ipUuoMCEbDQ" className="popup-youtube video-play-button">
                                    <i className="fas fa-play"></i>
                                    <div className="effect"></div>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1">
                            <div className="choose-us-style-one d-flex-between">
                                <div className="top-info">
                                    <h4 className="sub-title">Why Avrix</h4>
                                    <h2 className="title">Unlock revenue growth for your business</h2>
                                </div>
                                <div className="bottom-info">
                                    <ul className="list-item">
                                        <li>Fast Development <strong>01</strong></li>
                                        <li>Full Flexibility <strong>02</strong></li>
                                        <li>Modern Design <strong>03</strong></li>
                                        <li>Simple Maintenance <strong>04</strong></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="testimonial-style-one-area default-padding-top">

                <div className="container">
                    <div className="heading-left">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content-left">
                                    <h4 className="sub-title">Testimonials</h4>
                                    <h2 className="title">What people say</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="row">

                        <div className="testimonial-one-carousel-box">
                            <div className="testimonial-style-one-carousel swiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="testimonial-style-one">
                                            <div className="provider">
                                                <div className="thumb">
                                                    <img src="/assets/img/800x800.png" alt="8" />
                                                    <div className="quote">
                                                        <img src="/assets/img/shape/quote-big.png" alt="9" />
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <h4>Matthew J. Wyman</h4>
                                                    <span>Senior Consultant</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="rating">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                </div>
                                                <p>
                                                    Targeting consultation discover apartments. ndulgence off under folly death wrote cause her way spite. Plan upon yet way get cold spot its week. Almost do am or limits hearts. Resolve parties but why she shewing. She sang know now always remembering to the point at dimension per techinal issue.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-style-one">
                                            <div className="provider">
                                                <div className="thumb">
                                                    <img src="/assets/img/800x800.png" alt="10" />
                                                    <div className="quote">
                                                        <img src="/assets/img/shape/quote-big.png" alt="11" />
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <h4>Anthom Bu Spar</h4>
                                                    <span>Marketing Manager</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="rating">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                </div>
                                                <p>
                                                    Targeting consultation discover apartments. ndulgence off under folly death wrote cause her way spite. Plan upon yet way get cold spot its week. Almost do am or limits hearts. Resolve parties but why she shewing. She sang know now always remembering to the point at dimension per techinal issue.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="testimonial-style-one">
                                            <div className="provider">
                                                <div className="thumb">
                                                    <img src="/assets/img/800x800.png" alt="12" />
                                                    <div className="quote">
                                                        <img src="/assets/img/shape/quote-big.png" alt="13" />
                                                    </div>
                                                </div>
                                                <div className="info">
                                                    <h4>Metho k. Partho</h4>
                                                    <span>Senior Developer</span>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="rating">
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                    <i className="fas fa-star"></i>
                                                </div>
                                                <p>
                                                    Targeting consultation discover apartments. ndulgence off under folly death wrote cause her way spite. Plan upon yet way get cold spot its week. Almost do am or limits hearts. Resolve parties but why she shewing. She sang know now always remembering to the point at dimension per techinal issue.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="testimonial-one-swiper-nav">

                                <div className="testimonial-one-pagination"></div>

                                <div className="testimonial-one-button-prev"></div>
                                <div className="testimonial-one-button-next"></div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div className="multi-section overflow-hidden bg-dark-secondary text-light">
                <div className="bg-shape-top">
                    <img src="/assets/img/shape/bg-shape-1.png" alt="14" />
                </div>

                <div className="thecontainer">

                    <div className="panel overflow-hidden">
                        <div className="container overflow-hidden">
                            <div className="row align-center">
                                <div className="col-lg-4">
                                    <div className="site-title">
                                        <h4 className="sub-title">Award & Achivement </h4>
                                        <h2 className="title">Awards &  Recognitions</h2>
                                    </div>
                                </div>
                                <div className="col-lg-7 offset-lg-1">
                                    <div className="achivement-items">
                                        <div className="achivement-grid">
                                            <div className="achivement-item">
                                                <i className="fab fa-behance"></i>
                                                <h4>Behance Awards </h4>
                                            </div>
                                            <div className="achivement-item">
                                                <i className="fas fa-layer-group"></i>
                                                <h4>Design Awards</h4>
                                            </div>
                                        </div>
                                        <div className="achivement-grid">
                                            <div className="achivement-item">
                                                <i className="fab fa-laravel"></i>
                                                <h4>Programming Awards</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel overflow-hidden bg-gradient">
                        <div className="container overflow-hidden">
                            <div className="expertise-area text-center">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="site-title">
                                            <h4 className="sub-title">Our Expertise </h4>
                                            <h2 className="title">Best solutions provider</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="achivement-skill-items">
                                            <div className="progress-box">
                                                <h5>Programming Language</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" data-width="79">
                                                        <span>79%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progress-box">
                                                <h5>Backend Development</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" data-width="95">
                                                        <span>95%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progress-box">
                                                <h5>Product Design</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" data-width="80">
                                                        <span>80%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="progress-box">
                                                <h5>UI/UX</h5>
                                                <div className="progress">
                                                    <div className="progress-bar" role="progressbar" data-width="67">
                                                        <span>67%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="panel contact-panel overflow-hidden">
                        <div className="contact-panel-bg" style={{backgroundImage: "url(assets/img/about/3.jpg)"}}></div>
                        <div className="container overflow-hidden">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h4 className="sub-title">Have you project in mind?</h4>
                                    <h2 className="title">For instant support please reach us</h2>
                                    <a className="btn-round-animation dark mt-40" href="/">Send Mail <i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="blog-area home-blog default-padding bottom-less">

            </div>
        </>
    );
}

export default Radium(Index);
